import { z } from 'zod'
import { jobStateChangeStatesSchema, jobStateSchema } from './JobState.types'

export const causesSchema = z.record(z.string(), z.string().or(z.array(z.string())))
export type Causes = z.infer<typeof causesSchema>

export const jobEventMetadataSchema = z.object({
  causes: z.record(z.string(), z.array(z.string())).nullable().optional(),
  checked_pre_flight_checks: z.unknown(),
  corrective_action: z.unknown(),
  device: z
    .object({
      station: z
        .object({
          station_name: z.string(),
          zone_name: z.string(),
        })
        .optional()
        .nullable(),
    })
    .optional()
    .nullable(),
  device_id: z.string().nullable().optional(),
  in_process_check: z
    .object({
      order: z.number(),
      name: z.string().optional().nullable(),
    })
    .nullable()
    .optional(),
  job_batch_process_id: z.unknown(),
  job_batch_process_order: z.unknown(),
  job_card_id: z.unknown(),
  job_event_id: z.unknown(),
  job_event_metadata: z.unknown(),
  job_headcount: z.unknown(),
  job_workers: z.array(z.string()).nullable().optional(),
  job_worker_names: z.array(z.string()).nullable().optional(),
  note: z.unknown(),
  overrun_report: z
    .object({
      causes: causesSchema,
      note: z.string().nullable().optional(),
    })
    .optional()
    .nullable(),
  profile_id: z.unknown(),
  reasons: z.unknown(),
  report: z.unknown(),
  report_time: z.unknown(),
  reporter_name: z.unknown(),
  signature: z.unknown(),
  signer: z
    .object({
      worker_first_name: z.string().nullable().optional(),
      worker_last_name: z.string().nullable().optional(),
    })
    .nullable()
    .optional(),
  station: z.unknown(),
  updated_by: z.unknown(),
})

export const jobEventSchema = z.object({
  job_card_id: z.string(),
  job_event_type: jobStateSchema,
  job_event_metadata: jobEventMetadataSchema,
  job_event_id: z.string(),
  job_event_user: z.string(),
  job_event_occurrence: z.string(),
  job_cards: z.object({
    work_order_id: z.string(),
  }),
})
export type JobEvent = z.infer<typeof jobEventSchema>

export const workOrderSchema = z.object({
  work_order_id: z.string(),
  work_order_name: z.string(),
  bill_of_operations_versions: z.object({
    bill_of_operations_id: z.string(),
    bill_of_operations_name: z.string(),
    bill_of_operations_version: z.number(),
  }),
  work_order_taggings: z.array(
    z.object({
      work_order_tag: z.string(),
      work_order_tag_category: z.string(),
    }),
  ),
})

export const jobCardSchema = z.object({
  job_card_operation_name: z.string().nullable(),
  job_card_current_status: jobStateChangeStatesSchema,
  work_order_id: z.string(),
  zone_name: z.string().nullable(),
  station_name: z.string().nullable(),
  job_card_latest_headcount: z.number().nullable(),
  job_card_expected_headcount: z.number().nullable(),
  job_card_takt_time: z.string().nullable(),
  job_card_cycle_time: z.string().nullable(),
  job_card_downtime: z.string().nullable(),
  job_card_overrun_time: z.string().nullable(),
  job_card_started_at: z.string().nullable(),
  job_card_updated_at: z.string().nullable(),
  operation_versions: z.object({
    operation_name: z.string(),
  }),
  work_orders: workOrderSchema,
  job_workers: z.array(
    z.object({
      workers: z.object({
        worker_name: z.string(),
        worker_first_name: z.string(),
        worker_last_name: z.string(),
      }),
    }),
  ),
})
export type JobCard = z.infer<typeof jobCardSchema>
