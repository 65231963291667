import { QueryClient } from '@tanstack/react-query'
import { TFunction } from 'i18next'
import { z } from 'zod'
import { jobCardSchema, jobEventSchema } from '~/types/JobCard.typse'
import { mapJobStateTitle } from '~/types/JobState.types'
import { Database } from '~/types/database.types'
import convertToCSV from '~/utils/convertToCSV'
import { supabase } from '~/utils/supabaseClient'
import { formatEventMetadata } from './utils'

export const jobDetailQuery = (id: string) => ({
  queryKey: ['job', 'detail', id],
  queryFn: async () => {
    const { data, error } = await supabase
      .from('job_cards')
      .select(
        `
    job_card_operation_name,
    job_card_current_status,
    work_order_id,
    zone_name,
    station_name,
    job_card_latest_headcount,
    job_card_expected_headcount,
    job_card_takt_time,
    job_card_cycle_time,
    job_card_downtime,
    job_card_updated_at,
    job_card_overrun_time,
    job_card_started_at,
    operation_versions (
      operation_name
    ),
    job_workers(
      workers (
        worker_name,
        worker_first_name,
        worker_last_name
      )
    ),
    work_orders (
      work_order_id,
      work_order_name,
      bill_of_operations_versions (
        bill_of_operations_id,
        bill_of_operations_name,
        bill_of_operations_version
      ),
      work_order_taggings (
        work_order_tag,
        work_order_tag_category
      )
    )
    `,
      )
      .eq('job_card_id', id)
      .maybeSingle()

    if (error) {
      throw error
    }

    const job = jobCardSchema.parse(data)
    if (!job) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    return job
  },
})

export const jobEventsQuery = (options: {
  jobCardId: string
  pageIndex?: number
  pageSize?: number
}) => ({
  queryKey: ['job', 'events', options.jobCardId],
  queryFn: async () => {
    const request = supabase
      .from('job_events')
      .select('*, job_cards(work_order_id)', { count: 'exact' })
      .eq('job_card_id', options.jobCardId)
      .order('job_event_occurrence', {
        ascending: false,
      })
    if (options.pageIndex && options.pageSize) {
      request.range(
        options.pageIndex * options.pageSize,
        (options.pageIndex + 1) * options.pageSize - 1,
      )
    }
    const { data: rawJobEvents, count } = await request
    const { data: jobEvents, success, error } = z.array(jobEventSchema).safeParse(rawJobEvents)

    if (success === false) {
      throw new Error(error.errors.map((e) => e.message).join('\n'))
    }
    if (!jobEvents) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }
    // Get worker names for all job_workers_changed events
    const eventsWithWorkerNames = await Promise.all(
      jobEvents.map(async (event) => {
        if (
          event.job_event_type === 'job_workers_changed' ||
          event.job_event_type === 'started' ||
          event.job_event_type === 'batch_process_started' ||
          event.job_event_type === 'restarted'
        ) {
          const metadata = event?.job_event_metadata
          const { data } = await supabase
            .from('workers')
            .select('worker_name')
            .in('worker_id', metadata.job_workers ?? [])
          return {
            ...event,
            job_event_metadata: {
              ...metadata,
              job_worker_names: data?.map((worker) => worker.worker_name) ?? [],
            },
          }
        }
        return event
      }),
    )

    return {
      rows: eventsWithWorkerNames,
      pageCount: count && options.pageSize ? Math.ceil(count / options.pageSize) : null,
    }
  },
})

export const jobEventsCSVQuery = async (t: TFunction, jobCardId: string) => {
  const { data, error } = await supabase
    .from('job_events')
    .select('*, job_cards(work_order_id)', { count: 'exact' })
    .eq('job_card_id', jobCardId)
    .order('job_event_occurrence', {
      ascending: false,
    })

  const jobEvents = z.array(jobEventSchema).parse(data)
  if (!jobEvents) {
    throw new Response('', {
      status: 404,
      statusText: 'Not Found',
    })
  }

  const csv = convertToCSV(
    jobEvents?.map((row, index) => {
      return {
        event_type: mapJobStateTitle(t, row.job_event_type),
        event_occurrence: row.job_event_occurrence,
        event_mata_data: `"${formatEventMetadata(t, row, index, jobEvents)}"`,
      }
    }),
  )
  return {
    csv,
    error,
  }
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: { params: { jobCardId: string } }) => {
    const query = jobDetailQuery(params.jobCardId)
    return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
  }

type Operation = Database['public']['Tables']['operation_versions']['Row']
export type JobResponseSuccess = Database['public']['Tables']['job_cards']['Row'] & {
  operation_versions: Operation
}
