import { selector } from 'recoil'
import sessionStorage from './sessionStorage'

export const isInnovareUserCheck = selector({
  key: 'IsInnovareUser',
  get: ({ get }) => {
    const email = get(sessionStorage)?.user?.email
    return (
      (email?.endsWith('@innovaresystems.co.uk') ||
        email?.endsWith('@innovareoffsite.co.uk') ||
        (email?.startsWith('innovare+') && email?.endsWith('@trunkdevices.co.uk'))) ??
      false
    )
  },
})
