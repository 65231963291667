import { ChevronRightIcon } from '@chakra-ui/icons'
import { BreadcrumbItem, BreadcrumbLink, Text } from '@chakra-ui/react'
import { QueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CgPerformance } from 'react-icons/cg'
import { FaClipboardCheck, FaHandsHelping, FaUserClock } from 'react-icons/fa'
import { FaChartGantt } from 'react-icons/fa6'
import { ImProfile } from 'react-icons/im'
import {
  IoCalendar,
  IoCalendarOutline,
  IoConstruct,
  IoFlash,
  IoFolderOpenSharp,
  IoFootsteps,
  IoGitMerge,
  IoLayers,
  IoMan,
  IoMapOutline,
  IoRepeatOutline,
  IoStatsChartSharp,
} from 'react-icons/io5'
import { MdOutlineDevices } from 'react-icons/md'
import { SiProgress } from 'react-icons/si'
import {
  Link,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
} from 'react-router-dom'
import Layout from '~/routes/Layout'

import { Partner } from './api/partnersApi'

// Import guards
import RequireAuth from './routes/guards/RequireAuth'
import RequireFeatureFlag from './routes/guards/RequireFeatureFlag'
import RequireMFA from './routes/guards/RequireMFA'

// Lazy load pages
const ErrorPage500 = React.lazy(() => import('./routes/ErrorPage500'))
const DevicesIndexPage = React.lazy(() => import('./routes/admin_pages/devices/DevicesIndexPage'))
const TusksDetailsPage = React.lazy(() => import('./routes/admin_pages/devices/TusksDetailsPage'))
const PartnersIndexPage = React.lazy(
  () => import('./routes/admin_pages/partners/PartnersIndexPage'),
)
const PartnerDetailsPage = React.lazy(
  () => import('./routes/admin_pages/partners/PartnerDetailsPage'),
)
const ProfilesIndexPage = React.lazy(
  () => import('./routes/admin_pages/profiles/ProfilesIndexPage'),
)
const ProfileDetailsPage = React.lazy(
  () => import('./routes/admin_pages/profiles/ProfilesDetailsPage'),
)
const ChangePasswordPage = React.lazy(() => import('./routes/user_pages/ChangePasswordPage'))
const ConfirmSignInPage = React.lazy(() => import('./routes/auth_pages/ConfirmSignInPage'))
const MultiFactorAuthenticationChallengePage = React.lazy(
  () => import('./routes/auth_pages/MultiFactorAuthenticationChallengePage'),
)
const LoginPage = React.lazy(() => import('./routes/auth_pages/LoginPage'))
const StatusboardPage = React.lazy(() => import('./routes/main_pages/statusboard'))
const WorkOrdersTablePage = React.lazy(
  () => import('./routes/main_pages/schedules/WorkOrdersTablePage/WorkOrderTablePage'),
)
const WorkOrdersGanttPage = React.lazy(
  () => import('./routes/main_pages/schedules/WorkOrdersGanttPage'),
)
const WorkOrdersBoardPage = React.lazy(
  () => import('./routes/main_pages/schedules/WorkOrdersBoardPage'),
)
const WorkOrderDetailsPage = React.lazy(
  () => import('./routes/main_pages/schedules/WorkOrderDetailsPage'),
)
const ProgressIndexPage = React.lazy(
  () => import('./routes/main_pages/schedules/ProgressIndexPage'),
)
const JobsIndexPage = React.lazy(() => import('./routes/main_pages/job-cards/JobsIndexPage'))
const JobDetailsPage = React.lazy(() => import('./routes/main_pages/job-cards/JobDetailsPage'))
const ProductionTargetDetailsPage = React.lazy(
  () => import('./routes/main_pages/performance/TargetDetailsPage/TargetDetailsPage'),
)
const ManufacturingQualityPackIndexPage = React.lazy(
  () => import('./routes/main_pages/quality/ManufacturingQualityPackIndex'),
)
const LogisticsAndInstallQualityPackIndexPage = React.lazy(
  () => import('./routes/main_pages/quality/LogisticsAndInstallQualityPackIndex'),
)
const QualityLiveFeedPage = React.lazy(() => import('./routes/main_pages/quality/QualityFeed'))

const PerformancePage = React.lazy(
  () => import('./routes/main_pages/performance/PerformanceIndexPage'),
)
const WorkerEngagementIndexPage = React.lazy(
  () => import('./routes/main_pages/worker-engagement/WorkerEngagementIndex'),
)
const WorkerEngagementDetailsPage = React.lazy(
  () => import('./routes/main_pages/worker-engagement/WorkerEngagementDetails'),
)
const OperationsPage = React.lazy(() => import('./routes/production_planning_pages/operations'))
const OperationDetailsPage = React.lazy(
  () => import('./routes/production_planning_pages/operations/OperationDetailsPage'),
)
const ProductsIndexPage = React.lazy(
  () => import('./routes/production_planning_pages/products/ProductsIndexPage'),
)
const ProductDetailsPage = React.lazy(
  () => import('./routes/production_planning_pages/products/ProductDetailsPage'),
)
const WorkforceIndexPage = React.lazy(
  () => import('./routes/production_planning_pages/workforce/WorkforceIndexPage'),
)

const ChangeControlPage = React.lazy(
  () => import('./routes/production_planning_pages/change-control/ChangeControlPage'),
)
const DrawingsPage = React.lazy(
  () => import('./routes/production_planning_pages/drawings/DrawingsIndexPage'),
)
const MultiFactorAuthenticationEnrollPage = React.lazy(
  () => import('./routes/user_pages/MultiFactorAuthenticationEnrollPage'),
)
const QualityPackDetails = React.lazy(
  () => import('./routes/main_pages/quality/QualityPackDetails'),
)
const QualityCheckDetails = React.lazy(
  () => import('./routes/main_pages/quality/QualityCheckDetails'),
)
const ZonesIndexPage = React.lazy(
  () => import('./routes/production_planning_pages/zones/ZonesIndexPage'),
)

const ProjectIndexPage = React.lazy(
  () => import('./routes/production_planning_pages/projects/ProjectIndexPage/ProjectIndexPage'),
)

const ShiftPatternsIndexPage = React.lazy(
  () => import('./routes/production_planning_pages/shift-patters/ShiftPatternsIndexPage'),
)

// Import loaders
import { loader as andonLoader } from './routes/admin_pages/devices/AndonsDetailsPage/api'
import { loader as deviceLoader } from './routes/admin_pages/devices/TusksDetailsPage/api'
import { loader as partnerLoader } from './routes/admin_pages/partners/PartnerDetailsPage/api'
import { loader as jobLoader } from './routes/main_pages/job-cards/JobDetailsPage/api'
import { loader as qualityCheckLoader } from './routes/main_pages/quality/QualityCheckDetails/api'
import { loader as qualityPackLoader } from './routes/main_pages/quality/QualityPackDetails/api'
import { loader as workOrderLoader } from './routes/main_pages/schedules/WorkOrderDetailsPage/api'
import { loader as workerEngagementLoader } from './routes/main_pages/worker-engagement/WorkerEngagementDetails/api'
import { loader as operationLoader } from './routes/production_planning_pages/operations/OperationDetailsPage/api'
import { loader as productLoader } from './routes/production_planning_pages/products/ProductDetailsPage/api'

// Types
import { useHasFeature } from '@saas-ui-pro/feature-flags'
import { BsFillKanbanFill, BsGrid3X3GapFill } from 'react-icons/bs'
import { useRecoilValue } from 'recoil'
import { useDocumentTitle } from 'usehooks-ts'
import { isEssModularUserCheck } from './atoms/isEssUserCheck'
import { isInnovareUserCheck } from './atoms/isInnovareUserCheck'
import { isLowfieldTimberFrameUserCheck } from './atoms/isLowfieldTimberFrameUserCheck'
import { isTrunkUserCheck } from './atoms/isTrunkUserCheck'
import { LinkItemProps } from './layout/SidebarLayout'
import AndonsDetailsPage from './routes/admin_pages/devices/AndonsDetailsPage'
import type { Andon } from './routes/admin_pages/devices/AndonsDetailsPage/api'
import type { Device } from './routes/admin_pages/devices/TusksDetailsPage/api'
import DemoPage from './routes/demo_pages/DemoPage'
import type { JobResponseSuccess } from './routes/main_pages/job-cards/JobDetailsPage/api'
import type { QualityCheck } from './routes/main_pages/quality/QualityCheckDetails/api'
import type { QualityPack } from './routes/main_pages/quality/QualityPackDetails/api'
import type { WorkOrderDetails } from './routes/main_pages/schedules/WorkOrderDetailsPage/api'
import type { OperationDetails } from './routes/production_planning_pages/operations/types'
import type { ProductDetails } from './routes/production_planning_pages/products/ProductDetailsPage/api'
import TestingPage from './routes/testing_pages/TestingPage'

const queryClient = new QueryClient()

const AppLayout = ({ sidebarLinks }: { sidebarLinks: Array<LinkItemProps> }) => {
  const location = useLocation()
  const { t } = useTranslation(['translation'])
  const [title, setTitle] = useState('Hub | Artic')

  useEffect(() => {
    const getTitle = () => {
      const path = location.pathname
      const routes = [
        { path: '/statusboard', title: t('nav.statusboard') },
        { path: '/devices', title: t('nav.devices') },
        { path: '/partners', title: t('nav.partners') },
        { path: '/profiles', title: t('nav.profiles') },
        { path: '/quality', title: t('nav.quality') },
        { path: '/zones', title: t('nav.zones') },
        { path: '/operations', title: t('nav.operations') },
        { path: '/products', title: t('product', { count: 0 }) },
        { path: '/schedules/board', title: t('nav.board') },
        { path: '/schedules/progress', title: t('nav.progress') },
        { path: '/schedules/gantt', title: t('nav.gantt') },
        { path: '/schedules', title: t('nav.schedules') },
        { path: '/job-cards', title: t('nav.job_cards') },
        { path: '/feed', title: t('nav.live_feed') },
        { path: '/performance', title: t('nav.performance_and_target_kpi') },
        { path: '/worker-engagement', title: t('nav.worker_engagement') },
        { path: '/projects', title: t('projects', { count: 0 }) },
        { path: '/drawings', title: t('nav.drawings', { count: 0 }) },
        { path: '/workforce', title: t('nav.workforce', { count: 0 }) },
        { path: '/change-control', title: t('nav.change_control') },
        { path: '/shift-patterns', title: t('nav.shift-patterns') },
        { path: '/testing', title: t('Testing') },
      ]

      const route = routes.find((route) => path.startsWith(route.path))
      setTitle(route ? `${route.title} | Artic Hub` : 'Artic Hub')
    }
    getTitle()
  }, [location.pathname, t])

  useDocumentTitle(title)

  return <Layout sidebarLinks={sidebarLinks} />
}

export default function AppRouter() {
  const { t } = useTranslation(['translation'])
  const { profiles } = useHasFeature('profiles')
  const isTrunkUser = useRecoilValue(isTrunkUserCheck)
  const isEssModularUser = useRecoilValue(isEssModularUserCheck)
  const isInnovareUser = useRecoilValue(isInnovareUserCheck)
  const isLowfieldUser = useRecoilValue(isLowfieldTimberFrameUserCheck)

  const sidebarLinks = [
    {
      name: t('nav.statusboard'),
      icon: IoFlash,
      to: 'statusboard',
    },
    {
      name: t('nav.schedules'),
      icon: IoCalendar,
      children: [
        {
          name: t('nav.grid'),
          to: 'schedules/overview',
          icon: BsGrid3X3GapFill,
        },
        {
          name: t('nav.gantt'),
          to: 'schedules/gantt',
          hide: !isTrunkUser || isInnovareUser || isLowfieldUser,
          icon: FaChartGantt,
        },
        {
          name: t('nav.board'),
          to: 'schedules/board',
          icon: BsFillKanbanFill,
        },
        {
          name: t('nav.progress'),
          to: 'schedules/progress',
          hide: isLowfieldUser,
          icon: SiProgress,
        },
      ],
    },
    {
      name: t('nav.job_cards'),
      icon: IoConstruct,
      to: 'job-cards',
    },
    {
      name: t('nav.quality'),
      children: [
        {
          name: t('nav.live_feed'),
          icon: IoStatsChartSharp,
          to: 'feed',
        },
        {
          name: t('nav.quality_packs'),
          icon: FaClipboardCheck,
          to: 'quality',
        },
      ],
    },
    {
      name: t('nav.performance_and_target_kpi'),
      icon: CgPerformance,
      to: 'performance',
      hide: !isTrunkUser,
    },
    {
      name: t('nav.worker_engagement'),
      icon: FaUserClock,
      to: 'worker-engagement',
      hide: !isEssModularUser && !isTrunkUser,
    },
    {
      name: t('nav.production_planning'),
      children: [
        {
          name: t('nav.change_control'),
          icon: IoRepeatOutline,
          to: 'change-control',
        },
        {
          name: t('nav.drawings'),
          icon: IoFolderOpenSharp,
          to: 'drawings',
        },
        {
          name: t('nav.operations'),
          hide: !isTrunkUser,
          icon: IoGitMerge,
          to: 'operations',
        },
        {
          name: t('product', { count: 0 }),
          hide: !isTrunkUser,
          icon: IoLayers,
          to: 'products',
        },
        {
          name: t('projects', { count: 0 }),
          hide: !isTrunkUser,
          icon: IoMapOutline,
          to: 'projects',
        },
        {
          name: t('nav.shift-patterns', { count: 0 }),
          hide: !isTrunkUser,
          icon: IoCalendarOutline,
          to: 'shift-patterns',
        },
        {
          name: t('nav.workforce'),
          hide: !isTrunkUser,
          icon: IoMan,
          to: 'workforce',
        },
        {
          name: t('nav.zones'),
          hide: !isTrunkUser,
          icon: IoFootsteps,
          to: 'zones',
        },
      ],
    },
    {
      name: t('nav.admin'),
      hide: !profiles,
      children: [
        {
          name: t('nav.partners'),
          icon: FaHandsHelping,
          to: 'partners',
          hide: !isTrunkUser,
        },
        {
          name: t('nav.devices'),
          icon: MdOutlineDevices,
          to: 'devices',
          hide: !isTrunkUser,
        },
        {
          name: t('nav.profiles'),
          icon: ImProfile,
          to: 'profiles',
          hide: !profiles,
        },
      ],
    },
  ]

  return (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <Route errorElement={<ErrorPage500 />}>
            <Route
              element={
                <RequireAuth>
                  <RequireMFA>
                    <AppLayout sidebarLinks={sidebarLinks} />
                  </RequireMFA>
                </RequireAuth>
              }
            >
              <Route
                path="statusboard"
                element={<StatusboardPage />}
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/statusboard">
                      {t('nav.statusboard')}
                    </BreadcrumbLink>
                  ),
                }}
              />
              <Route
                path="schedules"
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/schedules">
                      {t('nav.schedules')}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route index element={<WorkOrdersTablePage />} />
                <Route path="gantt" element={<WorkOrdersGanttPage />} />
                <Route
                  path="board"
                  handle={{
                    crumb: () => (
                      <BreadcrumbLink as={Link} to="/schedules/board">
                        {t('nav.board')}
                      </BreadcrumbLink>
                    ),
                  }}
                  element={<WorkOrdersBoardPage />}
                />
                <Route
                  path="overview"
                  handle={{
                    crumb: () => (
                      <BreadcrumbLink as={Link} to="/schedules/overview">
                        {t('nav.schedules')}
                      </BreadcrumbLink>
                    ),
                  }}
                  element={<WorkOrdersTablePage />}
                />
                <Route
                  path="progress"
                  handle={{
                    crumb: () => (
                      <BreadcrumbLink as={Link} to="/schedules/progress">
                        {t('nav.progress')}
                      </BreadcrumbLink>
                    ),
                  }}
                  element={<ProgressIndexPage />}
                />
                <Route
                  path=":workOrderId"
                  element={<WorkOrderDetailsPage />}
                  loader={workOrderLoader(queryClient)}
                  handle={{
                    crumb: (data: WorkOrderDetails) => (
                      <BreadcrumbLink isCurrentPage>{data?.work_order_name}</BreadcrumbLink>
                    ),
                  }}
                />
              </Route>
              <Route
                path="job-cards"
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/job-cards">
                      {t('nav.job_cards')}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route index element={<JobsIndexPage />} />
                <Route
                  path=":jobCardId"
                  loader={jobLoader(queryClient)}
                  handle={{
                    crumb: (data: JobResponseSuccess) => (
                      <BreadcrumbLink isCurrentPage>{data?.job_card_id}</BreadcrumbLink>
                    ),
                  }}
                  element={<JobDetailsPage />}
                />
              </Route>
              <Route
                path="feed"
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/feed">
                      {t('nav.live_feed')}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route index element={<QualityLiveFeedPage />} />
              </Route>
              <Route
                path="quality"
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/quality">
                      {t('nav.quality')}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route index element={<Navigate replace to="manufacture" />} />
                <Route path={'manufacture'} element={<ManufacturingQualityPackIndexPage />} />
                <Route
                  path={'logistics-and-install'}
                  element={
                    <RequireFeatureFlag flag={isTrunkUser}>
                      <LogisticsAndInstallQualityPackIndexPage />
                    </RequireFeatureFlag>
                  }
                />

                <Route
                  path=":workOrderId"
                  loader={qualityPackLoader(queryClient)}
                  handle={{
                    crumb: (data: QualityPack) => (
                      <BreadcrumbLink as={Link} to={`/quality/${data?.work_order_id}`}>
                        {data?.work_order_name}
                      </BreadcrumbLink>
                    ),
                  }}
                >
                  <Route
                    index
                    element={<QualityPackDetails />}
                    loader={qualityPackLoader(queryClient)}
                  />
                  <Route
                    path=":jobCardId/:orderNumber"
                    element={<QualityCheckDetails />}
                    loader={qualityCheckLoader(queryClient)}
                    handle={{
                      crumb: (data: QualityCheck) => (
                        <BreadcrumbLink isCurrentPage>
                          {data?.in_process_check?.name ?? 'SWI'}
                        </BreadcrumbLink>
                      ),
                    }}
                  />
                </Route>
              </Route>
              <Route
                path="performance"
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/performance">
                      {t('performance')}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route
                  index
                  element={
                    <RequireFeatureFlag flag={isTrunkUser}>
                      <PerformancePage />
                    </RequireFeatureFlag>
                  }
                />
                <Route
                  path="productionTargetsDetails/:targetId"
                  element={
                    <RequireFeatureFlag flag={isTrunkUser}>
                      <ProductionTargetDetailsPage />
                    </RequireFeatureFlag>
                  }
                />
              </Route>
              <Route
                path="worker-engagement"
                element={
                  <RequireFeatureFlag flag={isEssModularUser || isTrunkUser}>
                    <Outlet />
                  </RequireFeatureFlag>
                }
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/worker-engagement">
                      {t('nav.worker_engagement')}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route
                  index
                  element={
                    <RequireFeatureFlag flag={isTrunkUser}>
                      <WorkerEngagementIndexPage />
                    </RequireFeatureFlag>
                  }
                />
                <Route
                  path=":workerId/:date"
                  element={<WorkerEngagementDetailsPage />}
                  loader={workerEngagementLoader(queryClient)}
                  handle={{
                    crumb: (data: any) => (
                      <BreadcrumbItem isCurrentPage>
                        <Text>{data.workerEngagement.workers.worker_name}</Text>
                        <ChevronRightIcon color="gray.500" />
                        <Text>
                          {dayjs(data.workerEngagement?.work_date).format('dddd D MMMM, YYYY')}
                        </Text>
                      </BreadcrumbItem>
                    ),
                  }}
                />
              </Route>
              {/* Production Planning */}
              <Route
                path="operations"
                element={
                  <RequireFeatureFlag flag={isTrunkUser}>
                    <Outlet />
                  </RequireFeatureFlag>
                }
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/operations">
                      {t('nav.operations')}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route index element={<OperationsPage />} />
                <Route
                  path=":operationId"
                  loader={operationLoader(queryClient)}
                  handle={{
                    crumb: (data: OperationDetails) => (
                      <BreadcrumbLink
                        isCurrentPage
                        as={Link}
                        to={`/operations/${data?.operation_id}`}
                      >
                        {data?.operation_name}
                      </BreadcrumbLink>
                    ),
                  }}
                >
                  <Route
                    index
                    element={<OperationDetailsPage />}
                    loader={operationLoader(queryClient)}
                  />
                </Route>
              </Route>
              <Route
                path="projects"
                element={
                  <RequireFeatureFlag flag={isTrunkUser}>
                    <Outlet />
                  </RequireFeatureFlag>
                }
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/projects">
                      {t('projects', { count: 0 })}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route index element={<ProjectIndexPage />} />
              </Route>
              <Route
                path="products"
                element={
                  <RequireFeatureFlag flag={isTrunkUser}>
                    <Outlet />
                  </RequireFeatureFlag>
                }
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/products">
                      {t('product', { count: 0 })}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route index element={<ProductsIndexPage />} />
                <Route
                  path=":billOfOperationsId/:billOfOperationsVersion"
                  element={<ProductDetailsPage />}
                  loader={productLoader(queryClient)}
                  handle={{
                    crumb: (data: ProductDetails) => (
                      <BreadcrumbLink isCurrentPage>
                        {data?.bill_of_operations_name} - {data?.bill_of_operations_key}
                      </BreadcrumbLink>
                    ),
                  }}
                />
                <Route
                  path=":billOfOperationsId"
                  element={<ProductDetailsPage />}
                  loader={productLoader(queryClient)}
                  handle={{
                    crumb: (data: ProductDetails) => (
                      <BreadcrumbLink isCurrentPage>
                        {data?.bill_of_operations_name} - {data?.bill_of_operations_key}
                      </BreadcrumbLink>
                    ),
                  }}
                />
              </Route>
              <Route
                path="shift-patterns"
                element={
                  <RequireFeatureFlag flag={isTrunkUser}>
                    <Outlet />
                  </RequireFeatureFlag>
                }
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/shift-patterns">
                      {t('projects', { count: 0 })}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route index element={<ShiftPatternsIndexPage />} />
              </Route>
              <Route
                path="workforce"
                element={
                  <RequireFeatureFlag flag={isTrunkUser}>
                    <Outlet />
                  </RequireFeatureFlag>
                }
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/workforce">
                      {t('workforce', { count: 0 })}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route index element={<WorkforceIndexPage />} />
              </Route>
              <Route
                path="change-control"
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/change-control">
                      {t('engineering_change_control', { count: 0 })}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route index element={<ChangeControlPage />} />
              </Route>
              <Route
                path="drawings"
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/drawings">
                      {t('drawings', { count: 0 })}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route index element={<DrawingsPage />} />
              </Route>
              <Route
                path="zones"
                element={
                  <RequireFeatureFlag flag={isTrunkUser}>
                    <Outlet />
                  </RequireFeatureFlag>
                }
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/workforce">
                      {t('zones', { count: 0 })}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route index element={<ZonesIndexPage />} />
              </Route>
              {/* Admin */}
              <Route
                path="partners"
                element={
                  <RequireFeatureFlag flag={isTrunkUser}>
                    <Outlet />
                  </RequireFeatureFlag>
                }
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/partners">
                      {t('partner', { count: 0 })}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route index element={<PartnersIndexPage />} />
                <Route
                  path=":partnerName"
                  element={<PartnerDetailsPage />}
                  loader={partnerLoader(queryClient)}
                  handle={{
                    crumb: (data: Partner) => (
                      <BreadcrumbLink isCurrentPage>{data?.partner_display_name}</BreadcrumbLink>
                    ),
                  }}
                />
              </Route>
              <Route
                path="devices"
                element={
                  <RequireFeatureFlag flag={isTrunkUser}>
                    <DevicesIndexPage />
                  </RequireFeatureFlag>
                }
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/devices">
                      {t('device', { count: 0 })}
                    </BreadcrumbLink>
                  ),
                }}
              />
              <Route
                path="tusks/:deviceId"
                element={<TusksDetailsPage />}
                loader={deviceLoader(queryClient)}
                handle={{
                  crumb: (data: Device) => (
                    <BreadcrumbLink isCurrentPage>{data?.device_name}</BreadcrumbLink>
                  ),
                }}
              />
              <Route
                path="andons"
                element={
                  <RequireFeatureFlag flag={isTrunkUser}>
                    <Outlet />
                  </RequireFeatureFlag>
                }
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/devices?tabIndex=1">
                      {t('device', { count: 0 })}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route
                  path=":hardwareId"
                  element={<AndonsDetailsPage />}
                  loader={andonLoader(queryClient)}
                  handle={{
                    crumb: (data: Andon) => (
                      <BreadcrumbLink isCurrentPage>{data?.hardware_id}</BreadcrumbLink>
                    ),
                  }}
                />
              </Route>
              <Route
                path="profiles"
                handle={{
                  crumb: () => (
                    <BreadcrumbLink as={Link} to="/profiles">
                      {t('profiles', { count: 0 })}
                    </BreadcrumbLink>
                  ),
                }}
                element={
                  <RequireFeatureFlag flag={profiles}>
                    <Outlet />
                  </RequireFeatureFlag>
                }
              >
                <Route index element={<ProfilesIndexPage />} />
                <Route path=":profileId" element={<ProfileDetailsPage />} />
              </Route>
              <Route path="change-password" element={<ChangePasswordPage />} />
              //! THIS IS FOR DEMO PURPOSES ONLY
              {window.location.hostname === 'demo.dumbo.works' && (
                <Route path="demo" element={<DemoPage />} />
              )}
              //! THIS IS FOR STAGING ONLY
              {(window.location.hostname === 'staging.dumbo.works' ||
                window.location.hostname === 'localhost') && (
                <Route path="testing" element={<TestingPage />} />
              )}
              <Route path="*" element={<Navigate replace to="/statusboard" />} />
            </Route>
            <Route
              path="login"
              element={
                <React.Suspense>
                  <LoginPage />
                </React.Suspense>
              }
            />
            <Route path="confirm-signin" element={<ConfirmSignInPage />} />
            <Route path="mfa-setup" element={<MultiFactorAuthenticationEnrollPage />} />
            <Route path="mfa-challenge" element={<MultiFactorAuthenticationChallengePage />} />
          </Route>,
        ),
      )}
    />
  )
}
