import { CreateToastFnReturn } from '@chakra-ui/react'
import { QueryClient } from '@tanstack/react-query'
import JsFileDownloader from 'js-file-downloader'
import { Dispatch, SetStateAction } from 'react'
import { CheckStatus } from '~/types/ProcessCheck.types'
import { supabase } from '~/utils/supabaseClient'

export type QualityPack = {
  work_order_id: string
  work_order_name: string
  bill_of_operations_versions: {
    bill_of_operations_name: string
    bill_of_operations_id: string
    bill_of_operations_version: number
  }
  work_order_taggings: {
    partner_name: string
    work_order_id: string
    work_order_tag: string
    work_order_tag_category: string
  }[]
  job_cards: {
    job_card_in_process_checks: { check_status: CheckStatus | null }[] | null
    operation_versions: {
      operation_in_process_checks: { [key: string]: any }[] | null
    }
  }[]
  total_checks: number
  addressed_checks: number
  passed_checks: number
  failed_checks: number
  corrected_checks: number
}

export const qualityPackQuery = (workOrderId: string) => ({
  queryKey: ['quality', 'pack', workOrderId],
  queryFn: async () => {
    const query = supabase
      .from('work_orders')
      .select(
        `
        work_order_id,
        work_order_name,
        work_order_taggings(*),
        bill_of_operations_versions(bill_of_operations_name, bill_of_operations_version, bill_of_operations_id),
        job_cards(
          job_card_in_process_checks,
          operation_versions(
            operation_in_process_checks
          )
        )
      `,
      )
      .eq('work_order_id', workOrderId)
      .single()

    const { data } = await query
    if (!data) {
      throw new Response('', {
        status: 404,
        statusText: 'Not Found',
      })
    }

    const qualityChecksStats = (data as unknown as QualityPack).job_cards?.reduce(
      (prev, card) => {
        const { passed, failed, corrected } = card.job_card_in_process_checks?.reduce(
          (prev, check) => ({
            passed:
              prev.passed +
              ((check.check_status && check.check_status === 'pass') ||
              check.check_status === 'not_applicable'
                ? 1
                : 0),
            failed: prev.failed + (check.check_status && check.check_status === 'fail' ? 1 : 0),
            corrected:
              prev.corrected + (check.check_status && check.check_status === 'corrected' ? 1 : 0),
          }),
          { passed: 0, failed: 0, corrected: 0 },
        ) ?? { passed: 0, failed: 0, corrected: 0 }
        return {
          total_checks:
            prev.total_checks + (card.operation_versions.operation_in_process_checks?.length ?? 0),
          addressed_checks: prev.addressed_checks + passed + failed + corrected,
          passed_checks: prev.passed_checks + passed,
          failed_checks: prev.failed_checks + failed,
          corrected_checks: prev.corrected_checks + corrected,
        }
      },
      {
        total_checks: 0,
        addressed_checks: 0,
        passed_checks: 0,
        failed_checks: 0,
        corrected_checks: 0,
      },
    )
    return { ...data, ...qualityChecksStats } as unknown as QualityPack | undefined
  },
})

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: any) => {
    const query = qualityPackQuery(params.workOrderId)
    return queryClient.getQueryData(query as any) ?? (await queryClient.fetchQuery(query))
  }

export const fetchPdfQueryData = async (
  setIsLoadingPDF: Dispatch<SetStateAction<boolean>>,
  t: (key: string) => string,
  workOrderId: string,
  workOrderName: string,
  toast: CreateToastFnReturn,
) => {
  setIsLoadingPDF(true)
  try {
    const fileUrl = `${import.meta.env.VITE_TRUNK_BACKEND_URL}/quality/download/${workOrderId}`
    const apikey = import.meta.env.VITE_APP_SUPABASE_ANON_KEY
    const authorization = `Bearer ${(await supabase.auth.getSession())?.data?.session?.access_token}`

    await new JsFileDownloader({
      url: fileUrl,
      method: 'GET',
      headers: [
        { name: 'apikey', value: apikey },
        { name: 'Authorization', value: authorization },
      ],
      filename: `${workOrderName}.pdf`,
    })

    setIsLoadingPDF(false)
    toast({
      title: t('download_successful'),
      status: 'success',
      isClosable: true,
    })
  } catch {
    setIsLoadingPDF(false)
    toast({
      title: t('download_failed'),
      description: t('error_occurred_while_downloading_pdf'),
      status: 'error',
      isClosable: true,
    })
  }
}
