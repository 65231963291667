import { FiltersAddButton } from '@saas-ui-pro/react'

const defaultListProps = {
  maxHeight: '500px',
  height: '100%',
  overflowY: 'auto' as const,
  sx: {
    '&::-webkit-scrollbar': {
      width: '8px',
      borderRadius: '8px',
      backgroundColor: 'gray.100',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.300',
      borderRadius: '8px',
    },
  },
}

const FilterButton = (props: any) => {
  return <FiltersAddButton {...props} listProps={defaultListProps} />
}

export default FilterButton
