import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { IntercomProvider } from 'react-use-intercom'
import { RecoilRoot } from 'recoil'

import App from './App'
import i18n from './utils/i18n'

import 'lightgallery/css/lg-thumbnail.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lightgallery.css'

import '@bryntum/gantt/gantt.material.css'
import { FeaturesOptions, FeaturesProvider } from '@saas-ui-pro/feature-flags'
import { ModalsProvider } from '@saas-ui/modals'
import { SaasProvider } from '@saas-ui/react'
import { supabaseIntegration } from '@supabase/sentry-js-integration'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import packageJson from '../package.json'
import { theme } from './theme'
import { supabase } from './utils/supabaseClient'

import posthog from 'posthog-js'

posthog.init(import.meta.env.VITE_APP_POSTHOG_KEY, {
  api_host: 'https://eu.i.posthog.com',
  person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
})

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
  release: packageJson.version,
  // BrowserTracing settings - does performance monitoring
  tracesSampleRate: 1.0,
  // Replay settings - records and replays sessions
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.browserProfilingIntegration(),
    supabaseIntegration(supabase, Sentry, {
      tracing: true,
      breadcrumbs: true,
      errors: true,
    }),
  ],
})

const options: FeaturesOptions = {
  segments: [
    {
      id: 'admin',
      attr: [
        {
          key: 'admin',
          value: true,
        },
      ],
      features: ['profiles'],
    },
  ],
}

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <FeaturesProvider value={options}>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <IntercomProvider appId={import.meta.env.VITE_APP_INTERCOM_APP_ID} autoBoot>
            <RecoilRoot>
              <SaasProvider theme={theme}>
                <ModalsProvider>
                  <App />
                </ModalsProvider>
              </SaasProvider>
            </RecoilRoot>
          </IntercomProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </FeaturesProvider>
  </React.StrictMode>,
)
